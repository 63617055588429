import { StyledIcon } from 'fitify-ui/src/Icon'
import { ThemeVariants } from 'fitify-ui/src/theme'
import { colors } from 'fitify-ui/src/theme/colors'
import { StyledContainer } from 'fitify-ui-landing/src/components/Container/Container.Styled'
import { tabletPortrait } from 'fitify-ui-landing/src/theme/breakpoints'
import { zIndexes } from 'fitify-ui-landing/src/theme/zIndexes'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import { NavigationVariant } from './NavigationMenu'

export const StyledNavigation = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(24px);
  z-index: ${zIndexes.navigation};
`

export const StyledNavigationContainer = styled(StyledContainer)`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledNavigationList = styled.ul<{ variant: NavigationVariant }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;

  ${({ variant }) => {
    if (variant === NavigationVariant.FOOTER) {
      return css`
        flex-direction: column;
        align-items: flex-start;
      `
    }

    if (variant === NavigationVariant.MOBILE) {
      return css`
        flex-direction: column;
        align-items: flex-start;
      `
    }

    if (variant === NavigationVariant.COPY) {
      return css`
        margin-top: 24px;
        gap: 8px;
      `
    }

    return null
  }}
`

export const StyledNavigationLogoLink = styled(Link)`
  display: flex;
`

export const StyledNavigationLink = styled.li<{
  variant: NavigationVariant
  active: boolean
  isHiddenOnMobile: boolean
}>`
  width: fit-content;
  font-family: 'Graphik LCG Web', sans-serif;
  font-style: normal;
  font-weight: 500;

  a {
    outline: none;

    span {
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom-color: ${colors.Black};
      }
    }
  }

  ${StyledIcon} {
    margin-right: 16px;
    top: 4px;
  }

  ${(props) =>
    props.isHiddenOnMobile &&
    css`
      ${tabletPortrait(css`
        display: none;
      `)}
    `}

  ${({ theme, variant, active }) => {
    if (variant === NavigationVariant.MOBILE) {
      return css`
        font-size: 24px;
        color: ${theme.variant === ThemeVariants.DIGITAL
          ? colors.Gray600
          : colors.Black};

        a > svg {
          color: ${theme.variant === ThemeVariants.DIGITAL
            ? colors.Black
            : colors.Black};
        }

        a:hover {
          color: ${theme.variant === ThemeVariants.DIGITAL
            ? colors.Black
            : colors.Green400};

          span {
            border-bottom-color: ${theme.variant === ThemeVariants.DIGITAL
              ? colors.Black
              : colors.Green400};
          }

          svg {
            color: ${theme.variant === ThemeVariants.DIGITAL
              ? colors.Teal400
              : colors.Green400};
          }
        }
      `
    }

    if (variant === NavigationVariant.FOOTER) {
      return css`
        font-size: 24px;
        color: ${theme.variant === ThemeVariants.DIGITAL
          ? colors.White
          : colors.Black};

        a > svg {
          color: ${theme.variant === ThemeVariants.DIGITAL
            ? colors.White
            : colors.Black};
        }

        a:hover {
          color: ${theme.variant === ThemeVariants.DIGITAL
            ? colors.Teal400
            : colors.Green400};

          span {
            border-bottom-color: ${theme.variant === ThemeVariants.DIGITAL
              ? colors.Teal400
              : colors.Green400};
          }

          svg {
            color: ${theme.variant === ThemeVariants.DIGITAL
              ? colors.Teal400
              : colors.Green400};
          }
        }
      `
    }

    if (variant === NavigationVariant.COPY) {
      return css`
        padding-right: 8px;
        border-right: 1px solid ${colors.Gray600};
        line-height: 140%;
        font-weight: 400;
        font-size: 14px;
        color: ${colors.Gray600};

        &:last-child {
          border-right: none;
        }

        &:hover {
          a span {
            color: ${theme.variant === ThemeVariants.DIGITAL
              ? colors.White
              : colors.Black};
            border-bottom-color: ${theme.variant === ThemeVariants.DIGITAL
              ? colors.White
              : colors.Black};
          }
        }
      `
    }

    return css`
      color: ${colors.Gray600};
      line-height: 120%;

      &:hover {
        a {
          color: ${colors.Black};
        }
      }

      ${active &&
      css`
        color: ${colors.Black};
      `}
    `
  }}
`

// MODAL
export const StyledNavigationMobileContainer = styled.div`
  display: none;

  ${tabletPortrait(css`
    display: initial;
  `)}
`

export const StyledNavigationMobile = styled(StyledContainer)`
  padding-top: 12px;
`

export const StyledNavigationMenu = styled.div`
  margin-top: 78px;
  padding-top: 64px;
`

export const StyledNavigationButton = styled.div`
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`

import { LinkTypes } from 'fitify-ui-landing/src/@types'

import { StyledNavigationList } from './Navigation.Styled'
import NavigationButton from './NavigationButton'
import NavigationLink from './NavigationLink'

export enum NavigationVariant {
  MOBILE = 'mobile',
  NAVIGATION = 'navigation',
  FOOTER = 'footer',
  COPY = 'copy',
}

const NavigationMenu = ({
  items,
  variant = NavigationVariant.NAVIGATION,
  onLinkClick,
}: {
  items: LinkTypes.Navigation[]
  variant?: NavigationVariant
  onLinkClick?: () => void
}) => {
  return (
    <StyledNavigationList variant={variant}>
      {items.map((item, index) => {
        if (item.type === 'button') {
          return <NavigationButton item={item} key={index} />
        }

        return (
          <NavigationLink
            item={item}
            key={index}
            onLinkClick={onLinkClick}
            variant={variant}
          />
        )
      })}
    </StyledNavigationList>
  )
}

export default NavigationMenu

import { Icon, Text, useDeviceSize } from 'fitify-ui'
import { useTranslation } from 'next-i18next'

import {
  StyledSpecialOffer,
  StyledSpecialOfferContent,
} from './SpecialOffer.Styled'

const NEXT_PUBLIC_FITIFY_PLUS_PROMO = process.env.NEXT_PUBLIC_FITIFY_PLUS_PROMO

const SpecialOffer = () => {
  const { t } = useTranslation()
  const { windowWidth } = useDeviceSize()
  const isPhoneBreakpoint = windowWidth < 600

  return (
    <StyledSpecialOffer href={NEXT_PUBLIC_FITIFY_PLUS_PROMO as string}>
      <StyledSpecialOfferContent>
        <Icon name={'fitify-logo-temporary'} color={'White'} />
        {isPhoneBreakpoint ? (
          <Text
            as={'span'}
            color={'White'}
            variant={'special-offer'}
            html={t('landing_deal1_part1') + ' ' + t('hc_landing_deal2')}
          />
        ) : (
          <>
            <Text as={'span'} variant={'special-offer'} color={'White'}>
              {t('landing_deal1_part1')}
            </Text>
            <Text
              as={'span'}
              color={'White'}
              variant={'special-offer'}
              html={t('hc_landing_deal2')}
            />
          </>
        )}
      </StyledSpecialOfferContent>
    </StyledSpecialOffer>
  )
}

export default SpecialOffer

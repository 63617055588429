import Icon from 'fitify-ui/src/Icon'
import { LinkTypes } from 'fitify-ui-landing/src/@types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { StyledNavigationLink } from './Navigation.Styled'
import { NavigationVariant } from './NavigationMenu'

const NavigationLink = ({
  item,
  variant = NavigationVariant.NAVIGATION,
  onLinkClick,
}: {
  item: LinkTypes.Navigation
  variant?: NavigationVariant
  onLinkClick?: () => void
}) => {
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <StyledNavigationLink
      variant={variant}
      active={router.pathname === item.link}
      isHiddenOnMobile={item.isHiddenOnMobile}
    >
      <Link
        href={item.link}
        locale={router.locale}
        target={item.link_type}
        onClick={onLinkClick}
      >
        {item.icon ? <Icon name={item.icon} /> : null}
        <span>{t(item.caption)}</span>
      </Link>
    </StyledNavigationLink>
  )
}

export default NavigationLink

import { Icon, Text } from 'fitify-ui'

import { ButtonLoader, ButtonStyles } from './Button.Styles'
import { ButtonVariants, ButtonProps } from './Button.Types'

const Button = ({
  children,
  href,
  loading,
  icon,
  variant = 'primary-blue',
  as = href ? 'a' : 'button',
  ...rest
}: ButtonProps) => {
  // @todo - remove this and use theme colors after new theme

  const resolveColor = (buttonVariant: ButtonVariants) => {
    switch (buttonVariant) {
      case 'primary-blue':
        return 'White'
      case 'primary-black':
        return 'Green300'
      case 'primary-green':
        return 'Black'
      case 'primary-trainer':
        return 'Black'
      case 'secondary-trainer':
        return 'Black'
      default:
        return 'White'
    }
  }

  return (
    <ButtonStyles
      aria-label={'button'}
      {...{ variant, as }}
      {...rest}
      href={href}
    >
      {loading ? (
        <ButtonLoader variant={variant} />
      ) : (
        <>
          <Text
            as={'span'}
            variant={'button'}
            fontWeight={500}
            fontFamily={'Graphik LCG Web'}
            color={resolveColor(variant)}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            {children}
          </Text>
          {icon ? <Icon name={icon} color={resolveColor(variant)} /> : null}
        </>
      )}
    </ButtonStyles>
  )
}

export default Button

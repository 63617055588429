import { StyledIcon, TextStyles, colors } from 'fitify-ui'
import { phone, xsPhone } from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledSpecialOfferContent = styled.div`
  display: flex;
  align-items: center;
  ${StyledIcon} {
    margin-right: 24px;
    width: 88px;
    min-width: 88px;
    height: 24px;
  }
  ${TextStyles} {
    ${xsPhone(css`
      font-weight: 600;
      font-size: 12px;
    `)};

    b {
      font-weight: 600;
    }
    &:nth-child(2) {
      ${phone(css`
        margin-right: 0;
      `)};
      margin-right: 5px;
    }
    &:nth-child(3) {
      ${phone(css`
        font-size: 12px;
      `)};
    }
  }
`

export const StyledSpecialOffer = styled.a`
  position: relative;
  width: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: ${colors.Black};
  padding: 8px;
  transition: all 200ms ease;
  &:hover {
    background-color: ${colors.White};
    ${StyledSpecialOfferContent} {
      ${StyledIcon} {
        color: ${colors.Black};
      }
      ${TextStyles} {
        color: #000;
      }
    }
  }

  ${phone(css`
    padding: 8px 24px;
  `)};
`
